//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StepLayout from './step-layout.vue';
import Button from '../components/button.vue';
import UiSwitch from '../components/switch.vue';
import emojiShrugging from '$resources/images/emoji-shrugging.png';
import IconUpload from '../components/icon-upload.vue';
import stepMixin from '../lib/step-mixin.js';

export default {
  components: {
    StepLayout,
    Button,
    UiSwitch,
    IconUpload
  },
  mixins: [stepMixin],
  props: {
    cups: {
      type: Object
    },
    channel: {
      type: String
    }
  },
  data: () => ({
    emojiShrugging,
    addBill: false,
    cupsId: '',
    loading: false,
    inputChanged: false,
    addFile: false,
    file: null
  }),
  watch: {
    // Eliminar espacios al escribir o pegar el CUPS
    cupsId(value) {
      const haveSpaces = value.indexOf(' ') !== -1 || value.match(/\s/g) !== null;

      if (haveSpaces) {
        this.cupsId = value.replace(/\s/g, '');
      }
    }
  },
  async created() {
    if (this.cups.type === 'cups') this.cupsId = this.cups.data;
    if (this.cups.type === 'pdf') this.addFile = true;
  },
  methods: {
    onFileDrop(event) {
      event.preventDefault();
      const [file] = event.dataTransfer.files;
      this.file = file;
    },
    sendGeneric() {
      this.loading = true;
      this.$emit('continue', {
        type: 'generic',
        data: ''
      });
    },
    handleInputChange() {
      const file = this.$refs.inputFile.files[0];

      this.file = file;
    },
    onContinue() {
      this.loading = true;

      if (this.addFile) {
        this.$emit('continue', {
          type: 'invoice-upload',
          action: 'hiring',
          data: this.file
        });
        return;
      }

      this.$emit('continue', {
        type: 'cups',
        action: 'hiring',
        data: this.cupsId
      });
    }
  }
};
